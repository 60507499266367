import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Label from '../Label';
import Button from '../Button';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import BannerOne from '../BannerOne';
import CancelButton from '../CancelButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { DynamicImage, Loader } from 'components';
import { getLocalisedProduct } from 'utils/localization';
import { updateLead, updateQuizAnswers } from 'state/user/effects';
import quizSvgs from 'utils/constants/quizSvgs';
import { Horoscope, Origin } from 'circular-natal-horoscope-js';
import useQuizAnswersBirthDetails from 'utils/hooks/useQuizAnswersBirthDetails';
import BirthChart from 'pages/start/quiz/components/BirthChart';
import CircleType from 'circletype';
import { resolveBookCoverName } from 'utils/hooks/resolveBookCoverName';

interface HardcoverProps {}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;

const LabelStyled = styled(Label)`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 2rem;
  @media ${tablet} {
    font-size: 0.875rem;
    margin-top: 1rem;
  }
`;

const ImageContainer = styled.div`
  max-width: 35rem;
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;

const ImageSectionStyled = styled(ImageSection)<{ width: string }>`
  width: 100%;
  max-width: 21.53125rem;
  margin-bottom: 2.5rem;
  @media ${tablet} {
    max-width: 16.34375rem;
    margin-bottom: 1.5rem;
  }
`;

const ImageSectionStyledBottom = styled(ImageSectionStyled)`
  width: 100%;
  max-width: 21.53125rem;
  margin-bottom: 0;
  @media ${tablet} {
    max-width: 16.34375rem;
  }
`;

const HeadlineBiggerContainer = styled.div`
  width: 100%;
  padding: 1rem 1rem 0;
  @media ${tablet} {
    padding-bottom: 0;
  }
  @media ${mobile} {
    max-width: 21.3rem;
  }
`;

const HeadlineBigger = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.032rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 150%;
    letter-spacing: -0.0165rem;
  }
`;

const HeadlineSmaller = styled.p`
  color: #363648;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.028rem;
  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 150%;
    letter-spacing: -0.01375rem;
  }
`;

const CancelButtonStyled = styled(CancelButton)``;

const BookContainer = styled.div`
  position: relative;
  max-width: 21.4rem;
  margin: 1.5rem 0 1rem;
  @media ${tablet} {
    margin: 1rem 0;
  }
`;

const BookImageContainer = styled.div`
  position: relative;
  div#paper {
    position: absolute !important;
    top: 76px;
    right: 50%;
    transform: translateX(50%);
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 14rem;
  background-size: contain;
`;

const BirthChartStyled = styled(BirthChart)`
  position: absolute !important;
  top: 0;
  div#paper {
    position: absolute !important;
    top: 0;
  }
`;

const SunSign = styled.div<{ color: string }>`
  display: flex;
  width: 27px;
  height: 27px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 59px;
  border: 0.532px solid ${({ color }) => color};
  position: absolute;
  bottom: 13px;
  left: 17px;
  gap: 1px;
`;

const MoonSign = styled(SunSign)`
  bottom: 13px;
  right: 18px;
  left: unset;
`;
const SunText = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-size: 3.5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: 'Montserrat', sans-serif;
  max-width: 17px;
  position: relative;
  text-transform: uppercase;
`;

const SvgSign = styled.div`
  width: 10px;
  height: 10px;
  position: relative;
  svg {
    position: absolute;
  }
`;

const Name = styled.p<{
  color: string;
  isSurname: boolean;
  isNameLongerThan10: boolean;
  isSurameLongerThan10: boolean;
}>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-family: 'Arno Pro';
  font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '12px' : '16px'};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '13px' : '16px'};
  letter-spacing: 2.183px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: absolute;
  ${({ isSurname }) => !isSurname && 'top: 3.4rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
    isSurname && (isNameLongerThan10 || isSurameLongerThan10) && 'top: 3.1rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
    isSurname &&
    !(isNameLongerThan10 || isSurameLongerThan10) &&
    'top: 2.9rem;'}
  
  right: 50%;
  transform: translateX(50%);
  text-wrap: nowrap;
`;

const Date = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  font-family: 'Montserrat';
  font-size: 6px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px; /* Reduced letter-spacing */
  position: absolute;
  bottom: 3rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 200px; /* Adjust the width as needed */
  text-transform: capitalize;
`;

const City = styled(Date)<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  bottom: 2.5rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f3f3f8;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2rem 1rem 3rem;
  }
`;

const Hardcover: FC<HardcoverProps> = ({
  bannerOne,
  paymentSteps,
  headline,
  planSection,
  PlanSectionComponent,
  headline2,
  headline3,
  label,
  imageSection2,
  imageSection,
  button,
  loading,
  handleUpgradeClick,
  localisedProduct,
  userHasBoughtPaperbackBook,
  handleCancelClick,
  cancelButton,
}) => {
  const { isTablet } = useQuery();
  const { quiz_answers, code } = useSelector((state: AppState) => state.user);
  const color = quiz_answers?.color;
  const contentColor = quiz_answers?.contentColor;

  const birthDetails = useQuizAnswersBirthDetails();

  const [showName, setShowName] = useState<boolean>(false);

  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return <Svg />;
  };

  const textRefPlace = useRef<HTMLDivElement>(null);
  const textRefDate = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRefPlace.current) {
      new CircleType(textRefPlace.current).dir(-1).radius(130);
    }
    if (textRefDate.current) {
      new CircleType(textRefDate.current).dir(-1).radius(110);
    }
  });

  const dispatch = useDispatch();
  if (!localisedProduct) {
    return null;
  }
  const localisedProductMain = getLocalisedProduct(localisedProduct);

  const upgradeHandler = () => {
    if (localisedProductMain?.key.includes('hardcover')) {
      dispatch(updateQuizAnswers({ hardCover: true }));
      dispatch(
        updateLead(code, {
          quiz_answers: { ...quiz_answers, hardCover: true },
        }),
      );
    }
    handleUpgradeClick?.();
  };

  const handleOnLoad = () => {
    setShowName(true);
  };

  const { isNameLongerThan10, isSurameLongerThan10, isSurname, formattedName } =
    resolveBookCoverName(quiz_answers?.name, quiz_answers?.surname);

  return (
    <>
      <BannerOne {...bannerOne} boughtPaperback={userHasBoughtPaperbackBook} />
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <HeadlineBiggerContainer>
        <HeadlineBigger>{headline}</HeadlineBigger>
      </HeadlineBiggerContainer>
      <BookContainer>
        <BookImageContainer>
          <StyledDynamicImage
            src={`books-mockups/${color}.png`}
            alt=""
            // handleOnLoad={handleOnLoad}
          />
          <BirthChartStyled
            year={birthDetails?.year as number}
            month={birthDetails?.month as number}
            day={birthDetails?.day as number}
            hour={birthDetails?.hours as number}
            minute={birthDetails?.minutes as number}
            latitude={quiz_answers?.birth_place.lat}
            longitude={quiz_answers?.birth_place.lng}
            color={contentColor}
            width={150}
            height={150}
          />
          <Name
            color={color}
            dangerouslySetInnerHTML={{ __html: formattedName }}
            isSurname={isSurname}
            isNameLongerThan10={isNameLongerThan10}
            isSurameLongerThan10={isSurameLongerThan10}
          />
          <Date color={color} ref={textRefDate}>
            {birthDetails && birthDetails.fullDate}
          </Date>

          <City color={color} ref={textRefPlace}>
            {`${quiz_answers?.birth_place.main_text}, ${quiz_answers?.birth_place.secondary_text}`}
          </City>
          <SunSign color={contentColor}>
            <SunText color={color}>SUN SIGN</SunText>
            <SvgSign>
              {renderSvgImage(`${color}${quiz_answers?.zodiacSign}`)}
            </SvgSign>
          </SunSign>
          <MoonSign color={contentColor}>
            <SunText color={color}>MOON SIGN</SunText>
            <SvgSign>
              {renderSvgImage(`${color}${quiz_answers?.planets.moon}`)}
            </SvgSign>
          </MoonSign>
        </BookImageContainer>
      </BookContainer>
      <PlanSectionComponent {...planSection} />
      <Container>
        <HeadlineSmaller>{headline2}</HeadlineSmaller>
        <HeadlineBigger>{headline3}</HeadlineBigger>
        <LabelStyled {...label} align={'center'} />
        <ImageContainer>
          <DynamicImage src={imageSection2.image} alt="Baby books" />
        </ImageContainer>
        {loading ? (
          <Loader wrapperHeight={'1'} />
        ) : (
          <Button
            bgColor="primary"
            disabled={loading}
            onClick={upgradeHandler}
            {...button}
            secondaryText={`${button.secondaryText} ${localisedProduct?.currency}${localisedProduct?.finalPrice}`}
          >
            {button.text}
          </Button>
        )}
        <CancelButtonStyled
          disabled={loading}
          onCancelClick={handleCancelClick}
          noButtonTitle={cancelButton.noButtonTitle}
        />
      </Container>
    </>
  );
};

export default Hardcover;
